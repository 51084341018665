<template>
  <div
    class="field"
    :class="{ 'is-horizontal': labelForInput, 'is-vertical': !labelForInput }"
  >
    <ui-component-modal
      style="position: fixed"
      v-if="showModalDatePicker"
      buttonLabelConfirm="Ready"
      :hideHeader="true"
      modalSize="dynamic"
      :onClickCancel="setHideModalDatePicker"
      :onClickSave="setNewDate"
      :showModal="showModalDatePicker"
      @closeModal="setHideModalDatePicker"
    >
      <template v-slot:content>
        <v-date-picker
          :attributes="attr"
          class="v-calendar-wrapper"
          style="position: static"
          :mode="isRange ? 'range' : 'single'"
          :columns="$screens(screens)"
          v-model="mDates"
          :is-inline="true"
          :is-required="true"
          :locale="$i18n.locale"
        />
      </template>
    </ui-component-modal>

    <div v-if="label" class="field-label is-normal has-text-left">
      <label ref="label" class="label">
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div class="field">
        <div
          class="control"
          :class="{ 'has-icons-left': iconLeft, 'has-icons-right': iconRight }"
        >
          <v-date-picker
            :attributes="attr"
            v-if="!openCalendarInModal"
            class="v-calendar-wrapper"
            style="position: static"
            :mode="isRange ? 'range' : 'single'"
            :columns="$screens(screens)"
            :locale="$i18n.locale"
            :is-required="true"
            v-model="mDates"
            :input-props="{
              class: 'input',
              readonly: true,
              value: formatDates(value),
            }"
          />

          <input
            v-if="openCalendarInModal"
            type="text"
            class="input"
            readonly
            :value="formatDates(mDates)"
            @click="setShowModalDatePicker"
            autocomplete="off"
            aria-autocomplete="both"
          />
        </div>
        <p v-if="error">
          <span class="help is-danger">
            {{ error }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VCalendar from 'v-calendar'
import BaseInputFieldController from '@/components/UI/Form/BaseInputFieldController'
Vue.use(VCalendar, {})

export default {
  extends: BaseInputFieldController,
  props: {
    dates: null,
    editMode: {
      type: Boolean,
      default: true,
    },
    iconLeft: {
      type: String,
      default: '',
    },
    iconRight: {
      type: String,
      default: '',
    },
    isRange: {
      type: Boolean,
      default: false,
    },
    labelForInput: {
      type: Boolean,
      default: true,
    },
    openCalendarInModal: {
      type: Boolean,
      default: false,
    },
    screens: {
      type: Object,
      default: function () {
        return { default: 1 }
      },
    },
    dataFormat: {
      type: String,
      default: 'longDateFormat',
    },
  },

  data() {
    return {
      attr: [
        {
          key: 'today',
          highlight: {
            color: 'purple',
            fillMode: 'none', // When upgrading to v2 change to outline
          },
          dates: new Date(),
        },
      ],
      fieldId: new Date().getTime(),
      showModalDatePicker: false,
      mDates: this.checkDates(),
    }
  },

  methods: {
    checkDates() {
      if (!this.isRange) {
        if (typeof this.value === 'number') {
          return new Date(this.value)
        }
        if (typeof this.value === 'string') {
          return this.$options.filters.parseIsoDateStringToDate(this.value)
        }
        return this.value
      } else {
        // Is range
        return {
          start:
            typeof this.value.start === 'string'
              ? this.$options.filters.parseIsoDateStringToDate(this.value.start)
              : this.value.start,
          end:
            typeof this.value.end === 'string'
              ? this.$options.filters.parseIsoDateStringToDate(this.value.end)
              : this.value.end,
        }
      }
    },

    formatDates(value) {
      let formattedDates = ''
      if (this.isRange) {
        formattedDates = this.$options.filters.dateFormat(
          value.start,
          this.$i18n.locale,
          this.dataFormat
        )
        formattedDates +=
          '  -  ' +
          this.$options.filters.dateFormat(
            value.end,
            this.$i18n.locale,
            this.dataFormat
          )
      } else {
        formattedDates = this.$options.filters.dateFormat(
          value,
          this.$i18n.locale,
          this.dataFormat
        )
      }
      return formattedDates
    },

    /**
     * Remove focus from input field
     */
    removeFocusFromInput() {
      let ref = 'datepicker-trigger' + this.fieldId
    },

    setNewDate() {
      // this.mDates = this.newDate
      this.$emit('updateDates', this.mDates)
      this.$emit('input', this.mDates)
      this.setHideModalDatePicker()
    },

    setShowModalDatePicker() {
      this.showModalDatePicker = true
    },

    setHideModalDatePicker() {
      this.showModalDatePicker = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template.scss';

.is-borderless {
  padding-left: 0;
  border-color: transparent;
  &:hover {
    border-color: transparent;
  }
}

.field.is-vertical {
  .label {
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: flex;
    align-items: center;

    .field-label {
      padding-top: unset;
      flex-basis: 150px;
      flex-grow: 0;
    }
  }
}
@media screen and (max-width: $desktop - ($gap * 2)) {
  .field.is-horizontal {
    display: block;

    .field-label {
      flex-basis: unset;
      flex-grow: unset;
      padding-top: 0.375em;
      margin-bottom: 0.5rem;
    }
  }
}
</style>
